import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = () => {
	return (
		<div className={styles.container}>
			<div className={styles.mainImg}>🚙</div>
			<div className={styles.roller}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default LoadingSpinner;
