import Logo from 'assets/img/logo.png';
import styles from './Home.module.scss';
import folderIcon from 'assets/img/folder.svg';
import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import { LoadingSpinner } from 'components';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

function getBase64(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

const Home = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const pictures = e.target.files;
		if (!pictures || !pictures[0]) return;
		const form = new FormData();
		const base64EncodedPicture = await getBase64(pictures[0]);
		const imageData = (base64EncodedPicture as string).split(',')[1];
		form.append('file', imageData);
		form.append('extension', 'jpeg');
		setIsLoading(true);
		try {
			const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/loader/run`, form);
			navigate('/report', { state: JSON.stringify(data) });
		} catch (error) {
			setIsLoading(false);
			setError(`Une erreur est survenue 🤷‍♂️ \n (code: ${(error as AxiosError).response?.status})`);
		}
	};
	return (
		<>
			{isLoading && <LoadingSpinner />}
			{!isLoading && (
				<main className={styles.wrapper}>
					<Helmet>
						<title>Quality Tracker 🚙 Accueil</title>
					</Helmet>
					<img src={Logo} alt="Quality Tracker" className={styles.logo} />
					<section className={styles.btnSection}>
						{error && <p className={styles.error}>{error}</p>}
						<label htmlFor="image" className={styles.imageInput}>
							<img src={folderIcon} alt="" height={16} width={16} />
							Importer une photo
							<input
								type="file"
								accept="image/jpg, image/jpeg, image/png"
								id="image"
								onChange={handleInputChange}
								style={{ display: 'none' }}
							/>
						</label>
					</section>
				</main>
			)}
		</>
	);
};

export default Home;
