import { Route, Routes } from 'react-router-dom';
import { Report, Home } from 'pages';

function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/report" element={<Report />} />
		</Routes>
	);
}

export default App;
