import React from 'react';
import styles from './Button.module.scss';

type Props = React.ComponentPropsWithoutRef<'button'>;

const Button: React.FC<Props> = ({ children, ...props }) => {
	return (
		<button className={styles.button} {...props}>
			{children}
		</button>
	);
};

export default Button;
