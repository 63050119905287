import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Report.module.scss';
import backArrow from 'assets/img/back.svg';
import { Button } from 'components';
import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

type ReportData = {
	locations: string[];
	segmentation: string;
	segmentation2: string;
	segmentation3: string;
};

const damages = {
	destruction: 'Destruction 💥',
	push: 'Impact 🥊',
	paintproblem: 'Rayure ⚡️',
	randomdamage: 'Dégâts 🔨',
};
const locations = {
	nan: 'Autre',
	parechocs: 'Pare-Chocs',
	parebrise: 'Pare-Brise',
	aile: 'Aile',
	capot: 'Capot',
	porte: 'Porte',
	basdecaisse: 'Bas de caisse',
};
type ReportType = {
	info: { location: string; type: string }[];
	pictures: { data: string; legend: string }[];
};

const Report = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const [modalState, setModalState] = useState<{ picture: string }>({ picture: '' });
	const [report, setReport] = useState<ReportType>();
	const [pdfIsLoading, setPdfIsLoading] = useState(false);

	useEffect(() => {
		try {
			const reportData: ReportData = JSON.parse(state as string);
			const reportPictures = [
				{
					data: `data:image/jpg;base64,${reportData.segmentation.substring(2).slice(0, -1)}`,
					legend: 'Détection des dégats',
				},
				{
					data: `data:image/jpg;base64,${reportData.segmentation2.substring(2).slice(0, -1)}`,
					legend: 'Détection des parties',
				},
				{
					data: `data:image/jpg;base64,${reportData.segmentation3.substring(2).slice(0, -1)}`,
					legend: 'Détection hybride',
				},
			];
			const reportInfo = reportData.locations.map((loc) => {
				const locationKeys = loc
					.split('sur')[1]
					.trim()
					.split(',')
					.map((key) => {
						const cleanKey = key.replace(/[^a-zA-Z ]/g, '').trim();
						return locations[cleanKey as 'nan' | 'porte' | 'parechocs' | 'aile' | 'parebrise'];
					});
				const damageKey = loc.split('sur')[0].trim().replace(' ', '');
				const type = damages[damageKey as 'destruction' | 'push' | 'randomdamage' | 'paintproblem'];
				const location = locationKeys.join(' / ');
				return {
					location,
					type,
				};
			});
			setReport({ info: reportInfo, pictures: reportPictures });
		} catch {
			navigate('/', { replace: true });
		}
	}, [navigate, state]);

	const downloadReportAsPdf = async () => {
		setPdfIsLoading(true);
		const htmlToExport = document.getElementById('pdfExportScope');
		if (!htmlToExport) return;
		const canvas = await html2canvas(htmlToExport, {
			backgroundColor: '#FFF',
			windowWidth: 1980,
			windowHeight: 1200,
		});
		const img = canvas.toDataURL('image/jpeg, 1.0');
		const imgWidth = 297;
		const imgHeight = canvas.height * (297 / canvas.width);
		const pdf = new jsPDF('l', 'mm', 'a4');
		pdf.addImage(img, 'png', 0, 20, imgWidth, imgHeight);
		pdf.save(`QualityTracker_Report.pdf`);
		setPdfIsLoading(false);
	};

	return (
		<>
			<Helmet>
				<title>Quality Tracker 🚙 Rapport</title>
			</Helmet>
			<header className={styles.header}>
				<Link to="/" className={styles.backLink}>
					<img src={backArrow} alt="Retour à l'accueil" />
				</Link>
				<h1 className={styles.title}>Rapport</h1>
			</header>
			<main className={styles.wrapper}>
				<div id="pdfExportScope">
					<section className={styles.listWrapper}>
						<div className={styles.pictureList}>
							{report?.pictures?.map((img) => (
								<div>
									<img
										src={img.data}
										key={img.legend}
										alt={img.legend}
										className={styles.picture}
										onClick={() => setModalState({ picture: img.data })}
									/>
									<p className={styles.legend}>{img.legend}</p>
								</div>
							))}
						</div>
					</section>
					<section className={styles.report}>
						{report?.info &&
							report?.info.map(({ location, type }, idx) => (
								<div className={styles.infoLine} key={idx}>
									<span className={styles.location}>{location}</span>
									<span className={styles.type}>{type || 'Rien à signaler ✨'}</span>
								</div>
							))}
						{!report?.info.length && (
							<div style={{ textAlign: 'center' }}>Nous n'avons rien détécté</div>
						)}
					</section>
				</div>
				<footer className={styles.footer}>
					<Button onClick={downloadReportAsPdf} disabled={pdfIsLoading}>
						Télécharger
					</Button>
				</footer>
			</main>

			<Modal
				isOpen={!!modalState.picture}
				appElement={document.body}
				onRequestClose={() => setModalState({ picture: '' })}
				className={styles.modal}
				overlayClassName={styles.overlay}
			>
				<img src={modalState.picture} alt="" className={styles.fullScreenPicture} />
			</Modal>
		</>
	);
};

export default Report;
